const black = "#000000";
const white = "#FFF";
const brokenWhite = "#FAFAFA";
const grey = "#4E4E4E";
const bordergrey = "#D9D9D9";
const borderHero = "#fafafa4d";
const overlaySlideshow = "#00000090";
const overlayGallery = "#000000d9";
const overlayProjectGrid = "#00000060";
const overlayProject = "#00000030";
const theme = {
  common: {
    black,
    white,
    brokenWhite,
    grey,
    bordergrey,
    borderHero,
    overlaySlideshow,
    overlayProjectGrid,
    overlayGallery,
    overlayProject,
  },
};
export default theme;
